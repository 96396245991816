/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, ChangeDetectionStrategy, input, Signal, computed } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

import { DataCapacityInBytes } from '@wndr/common/core/models/data-capacity';

import {
	ValidationErrorCode,
	MatchErrorData,
	LengthErrorData,
	AppErrorData,
	MinValueErrorData,
	MaxValueErrorData,
	MaxFileSizeErrorData,
	MaxFilesCountErrorData,
	EmptyContentTypeErrorData,
	MinFileSizeErrorData,
} from '../../../../core/models/validation-error-code';

const DEFAULT_ERROR_MESSAGE = 'Value is not valid';

const VALIDATION_ERROR_MESSAGE_FACTORIES = {
	[ValidationErrorCode.Email]: () => 'Email is not valid',
	[ValidationErrorCode.Required]: () => 'This field is required',
	[ValidationErrorCode.Match]: ({ controlTitle }: MatchErrorData) => `Value does not match with "${controlTitle}"`,
	[ValidationErrorCode.NotMatch]: ({ controlTitle }: MatchErrorData) => `Value shouldn't match with "${controlTitle}"`,
	[ValidationErrorCode.MinLength]: ({ requiredLength }: LengthErrorData) => `Minimal length is ${requiredLength}`,
	[ValidationErrorCode.MaxLength]: ({ requiredLength }: LengthErrorData) => `Maximum length is ${requiredLength} characters`,
	[ValidationErrorCode.Pattern]: () => 'Value does not satisfy the pattern',
	[ValidationErrorCode.AppError]: ({ message }: AppErrorData) => message,
	[ValidationErrorCode.Min]: ({ min }: MinValueErrorData) => `Minimum value is ${min}`,
	[ValidationErrorCode.Max]: ({ max }: MaxValueErrorData) => `Maximum value is ${max}`,
	[ValidationErrorCode.ZipCode]: () => `ZIP code should be XXXXX or XXXXX-XXXX`,
	[ValidationErrorCode.Greater]: ({ controlTitle }: any) => `The value should be greater than ${controlTitle}`,
	[ValidationErrorCode.MaxFileSize]: ({
		maxInBytes,
		filename,
	}: MaxFileSizeErrorData) => `
		The ${filename ?? 'file'} is too large.
		Allowed maximum size is ${DataCapacityInBytes.toMegabytes(maxInBytes).toFixed(0)} MB
	`,
	[ValidationErrorCode.PhoneNumber]: () => 'Phone number is incorrect',
	[ValidationErrorCode.FaxNumber]: () => 'Fax number is incorrect',
	[ValidationErrorCode.NPI]: () => 'NPI number is incorrect',
	[ValidationErrorCode.MaxFileCount]: ({
		requiredCount,
	}: MaxFilesCountErrorData) => `Maximum number of files to upload is ${requiredCount}`,
	[ValidationErrorCode.EmptyContentType]: ({
		filename,
	}: EmptyContentTypeErrorData) => `The ${filename} has an empty content type`,
	[ValidationErrorCode.MinFileSize]: ({
		filename,
		minInBytes,
	}: MinFileSizeErrorData) => `
		The ${filename ?? 'file'} is too small.
		Allowed minimum size is ${DataCapacityInBytes.toMegabytes(minInBytes).toFixed(0)} MB
	`,
};

/**
 * Validation error renderer component.
 * Renders first error from control errors.
 */
@Component({
	selector: 'wndrc-validation-message',
	templateUrl: './validation-message.component.html',
	styleUrls: ['./validation-message.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
})
export class ValidationMessageComponent {

	/** Validation errors. */
	public readonly errors = input<ValidationErrors | null>(null);

	/** Error message. */
	protected readonly errorMessage = this.initializeErrorMessage();

	private initializeErrorMessage(): Signal<string | null> {
		return computed(() => {
			const errors = this.errors();
			if (errors == null) {
				return null;
			}

			const errorCode = Object.keys(errors)[0] as ValidationErrorCode;
			return this.getErrorMessage(errorCode, errors[errorCode]);
		});
	}

	/**
	 * Get error message for specific validation error.
	 * @param errorCode Error code (minlength, required and etc.).
	 * @param errorData Data of error. See details of HTTP validation errors or implementation of custom.
	 * For instance data of minlength error is: { actualLength, requiredLength }.
	 */
	private getErrorMessage(
		errorCode: ValidationErrorCode,
		errorData: any,
	): string {
		const factory = VALIDATION_ERROR_MESSAGE_FACTORIES[errorCode];
		if (factory == null) {
			return DEFAULT_ERROR_MESSAGE;
		}
		return factory(errorData);
	}
}
